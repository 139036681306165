'use strict'
var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value)
          })
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value))
        } catch (e) {
          reject(e)
        }
      }
      function rejected(value) {
        try {
          step(generator['throw'](value))
        } catch (e) {
          reject(e)
        }
      }
      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected)
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next())
    })
  }
Object.defineProperty(exports, '__esModule', { value: true })
exports.AuthContext = void 0
const jsx_runtime_1 = require('react/jsx-runtime')
const core_1 = require('@telekomconsalting/core')
const dex_guru_internal_sdk_1 = require('@telekomconsalting/dex-guru-internal-sdk')
const authService_1 = require('@telekomconsalting/dex-guru-internal-sdk/lib/services/authService')
const react_1 = require('react')
const eagerConnect_1 = require('./eagerConnect')
const REFRESH_TOKEN_INTERVAL = 43200000
const noop = () => {
  return undefined
}
const asyncNoop = () =>
  __awaiter(void 0, void 0, void 0, function* () {
    return undefined
  })
exports.AuthContext = (0, react_1.createContext)({
  forceDisconnect: false,
  setForceDisconnect: (v) => v,
  closeProviderMenu: noop,
  openProviderMenu: noop,
  onConnect: asyncNoop,
  isSupportedChainId: true,
  isAuth: false,
  accountId: null,
})
const AuthWrapper = ({
  sdkCheckAuth,
  sdkHasToken,
  sdkRefreshToken,
  web3React,
  dexGuruAPIV2Url,
  dexGuruAPIV3Url,
  onWalletConnectionError,
  onWalletConnect,
  children,
  networksConfig,
  headers,
  onOpenProviderMenu,
  onCloseProviderMenu,
  isOpenProviderMenu,
  walletConnectors,
}) => {
  const { account, connector, deactivate, activate, library, chainId, error: web3Error } = web3React
  const [forceDisconnect, setForceDisconnect] = (0, react_1.useState)(false)
  const [isSupportedChainId, setIsSupportedChainId] = (0, react_1.useState)(true)
  const [accountId, setAccountId] = (0, react_1.useState)(null)
  const onConnect = (0, react_1.useRef)(asyncNoop)
  ;(0, react_1.useEffect)(() => {
    if (web3Error instanceof core_1.UnsupportedChainIdError) {
      walletConnectors === null || walletConnectors === void 0
        ? void 0
        : walletConnectors.disconnect(connector, deactivate)
    }
    setIsSupportedChainId(
      !(chainId !== undefined && !networksConfig.map((x) => x.id).includes(chainId))
    )
  }, [chainId, web3Error])
  ;(0, react_1.useEffect)(() => {
    const accessToken = dex_guru_internal_sdk_1.auth.getAccessToken()
    const accountId = dex_guru_internal_sdk_1.auth.getAccountId()
    setAccountId(accountId)
    if (!accessToken && accountId) {
      ;(0, eagerConnect_1.setSuppressEagerConnection)(false)
    }
    const interval = setInterval(() => {
      refreshToken(headers)
    }, REFRESH_TOKEN_INTERVAL || 43200000)
    return () => clearInterval(interval)
  }, [])
  ;(0, react_1.useEffect)(() => {
    if (!account) {
      setAccountId(null)
      setForceDisconnect(false)
    } else {
      if (walletConnectors) {
        checkAuth(account, walletConnectors)
      }
    }
  }, [account, walletConnectors])
  const checkAuth = (account, walletConnectors) =>
    __awaiter(void 0, void 0, void 0, function* () {
      yield sdkCheckAuth(
        dexGuruAPIV3Url,
        account,
        library,
        () => {
          console.log('*** on success', connector)
          if (connector) {
            walletConnectors
              .connect(connector, activate, (error) => {
                console.error(error)
                onWalletConnectionError && onWalletConnectionError(error)
              })
              .then(() =>
                __awaiter(void 0, void 0, void 0, function* () {
                  console.log('*** on success 101')
                  const account = yield connector.getAccount()
                  console.log('*** on success 102', account)
                  onWalletConnect && (yield onWalletConnect(account))
                  console.log('*** on success 103')
                })
              )
              .catch((error) => {
                console.log('*** on success error', error)
              })
          }
          ;(0, eagerConnect_1.setSuppressEagerConnection)(false)
          setAccountId(dex_guru_internal_sdk_1.auth.getAccountId())
        },
        () => {
          console.log('*** on fail')
          ;(0, eagerConnect_1.setSuppressEagerConnection)(false)
          setForceDisconnect(true)
          setAccountId(null)
        }
      )
      isOpenProviderMenu && closeProviderMenu()
    })
  const refreshToken = (headers) =>
    __awaiter(void 0, void 0, void 0, function* () {
      if (!sdkHasToken()) {
        return
      }
      const { error } = yield sdkRefreshToken(dexGuruAPIV2Url, headers)
      if (error) {
        setForceDisconnect(false)
        console.error('Unable to refresh session, you are logged out')
      }
    })
  const openProviderMenu = (callback = asyncNoop) => {
    onOpenProviderMenu()
    setForceDisconnect && setForceDisconnect(false)
    onConnect.current = callback
  }
  const closeProviderMenu = () => {
    const hasAccess = dex_guru_internal_sdk_1.auth.getAccessToken()
    if (!hasAccess) {
      walletConnectors === null || walletConnectors === void 0
        ? void 0
        : walletConnectors.disconnect(connector, deactivate)
    }
    onCloseProviderMenu()
  }
  return (0, jsx_runtime_1.jsx)(
    exports.AuthContext.Provider,
    Object.assign(
      {
        value: {
          forceDisconnect,
          setForceDisconnect,
          openProviderMenu,
          closeProviderMenu,
          isSupportedChainId,
          isAuth: !!accountId,
          onConnect: (account) =>
            __awaiter(void 0, void 0, void 0, function* () {
              setAccountId((0, authService_1.getAccountId)())
              onConnect.current && onConnect.current(account)
            }),
          accountId,
        },
      },
      { children: children }
    ),
    void 0
  )
}
exports.default = AuthWrapper
